<template lang="pug">
.row.mx-0.px-0
  .link.text-left.pb-1.px-0(
    v-if="fieldLabel",
    :class="labelPos==='top'?'col-12':'col-12 col-md-3'")
    template(v-if="chip && chip.text")
      div.d-flex.justify-content-between.align-items-center
        span.field-label(:class="labelPos==='top'?'font-sm':'font-md'") {{ fieldLabel }}
        nova-chip( :variant="chip.variant") {{ chip.text }}
    template(v-else)
      span(:class="labelPos==='top'?'font-sm':'font-md'") {{ fieldLabel }}

  .px-0(:class="labelPos==='top'?'w-100':'col-12 col-md-9'")
    .row.mx-0.p-0.input-wrapper(:class="[state, customClasses, readonly || disabled ? 'bg-gray-200':'']")
      label(:for="`n-inline-input-${uid}`")
        .d-inline-block.h-100.font-sm.pl-3.py-0.cursor-text(v-if="imageIcon")
          .text-center.icon-position(:class="'icon-'+imageIcon")
      .d-inline-block.py-0(:class="getInputWidthClass()")
        input.nova-input.inline-input.px-2.border-0.w-100(
          :id="`n-inline-input-${uid}`",
          v-bind="{...$props, ...$attrs}",
          v-model="ctrlValue",
          :type="inputType",
          ref="inputField")
      .d-inline-block.h-100.pr-3.py-0(v-if="rightImageIcon")
        .text-center.icon-position(:class="'icon-'+rightImageIcon")

    .input-sub-text.text-left.pt-2(v-if="validationMessage", :class="state")
      span.pr-1.sub-text-icon(v-if="state && state !== 'disabled'", :class="getSubtextIcon()")
      span {{ validationMessage }}
    .input-sub-text.link.text-left.pt-2(v-if="subInfoLink")
      span.pr-1.icon-plus-circle.sub-text-icon
      b-link.font-sm(:href="subInfoLink", target="_blank") Learn more about this
    .input-sub-text.text-left.pt-2(v-if="subText")
      span {{ subText }}

//b-input-group.p-0.input-wrapper.overflow-hidden
  b-input-group-prepend
    i.mt-1(:class="'icon-'+imageIcon")
  b-form-input.border-0.p-0.neg-mt-1(v-bind="$props", v-model="ctrlValue")
  b-input-group-append
    i(:class="'icon-'+rightImageIcon")
</template>

<script>
import NovaChip from "./NovaChip.vue";

export default {
  name: "NInlineInput",
  components: {
    NovaChip,
  },
  props: {
    /* eslint-disable-next-line vue/require-prop-types, vue/require-default-prop */
    value: {},
    fieldLabel: {
      type: String,
      default: null,
    },
    labelPos: {
      type: String,
      default: "top",
    },
    placeholder: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: null,
    },
    customClasses: {
      type: Array,
      default: () => [],
    },
    imageIcon: {
      type: String,
      default: null,
    },
    rightImageIcon: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    subText: {
      type: String,
      default: null,
    },
    validationMessage: {
      type: String,
      default: null,
    },
    subInfoLink: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    chip: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const uid = Date.now().toString();
    return {
      uid,
    };
  },
  computed: {
    ctrlValue: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.inputType === "number") {
          if (value === "0") {
            this.$emit("input", null);
          } else {
            this.$emit("input", parseFloat(value));
          }
        } else {
          this.$emit("input", value);
        }
      },
    },
  },
  methods: {
    getInputWidthClass() {
      if (this.imageIcon && this.rightImageIcon) {
        return "w-input-both-icons";
      }
      if (this.imageIcon || this.rightImageIcon) {
        return "w-input-one-icon";
      }
      return "w-100";
    },
    getSubtextIcon() {
      if (this.state === "success") {
        return "icon-check-circle";
      }
      if (this.state === "error") {
        return "icon-error";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
input {
  border-width: 0;
  padding: 0;
  background-color: inherit;
  cursor: inherit;
}
.inline-input {
  min-height: 2.25rem !important;
  box-sizing: border-box;
}
.icon-position {
  position: relative;
  top: 0.5rem;
  width: 1.25rem;
}
.w-input-one-icon {
  width: calc(100% - 2.25rem);
}
.w-input-both-icons {
  width: calc(100% - 5rem);
}
.border-radius-inherit {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.sub-text-icon {
  font-size: 1rem;
  vertical-align: text-bottom;
}
</style>
