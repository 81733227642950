<template lang="pug">
<!-- Instant click on option, closes the modal, issue from vue-select https://github.com/sagalbot/vue-select/issues/1272 -->
div
  vue-select(
    v-model="value",
    :options="options",
    label="name",
    value-attribute="id",
    :disabled="disabled",
    :placeholder="schema.placeholder",
    :filterable="false",
    @search="getSearchResults",
    :readonly="schema.readonly",
    :components="{ OpenIndicator, Deselect }",
    :taggable="schema.taggable",
    :multiple="schema.multiple")
    template(v-slot:option="opt")
      | {{ `${opt.name} ${schema.queryName === "users" ? "("+opt.email+")" : ""}` }}&nbsp;
      span.text-muted.small(v-if="schema.getSubTitle") {{ schema.getSubTitle(opt) }}
  span.form-text.text-muted.small(v-if="isSelectedVisible")
    | Selected:&nbsp;
    b-link.font-sm(
      :href="schema.getLink? schema.getLink(value) :\
        isPolicy ? `/admin/policies/${value.id}` : `/admin/${schema.queryName}/${value.id}`"
      target="_blank")
      | {{ value.name }}&nbsp;
      span.text-muted.small(v-if="schema.getSubTitle") {{ schema.getSubTitle(value) }}&nbsp;
      i.fa.fa-external-link
  .bg-alabaster(v-if="preview")
    span.form-text.text-muted.small Preview:
    .p-2(v-html="preview")

</template>

<script>
import VueSelect from "vue-select";
import { abstractField } from "vue-form-generator";
import { debounce } from "lodash-es";

import "vue-select/dist/vue-select.css";

export default {
  components: {
    VueSelect,
  },
  mixins: [abstractField],
  data() {
    return {
      options: [],
      meta: [],
      OpenIndicator: {
        render: (createElement) =>
          createElement("span", {
            class: { "icon-chevron-down text-gray-700": true },
          }),
      },
      Deselect: {
        render: (createElement) =>
          createElement("span", {
            class: { "icon-cross text-gray-700 ic-small": true },
          }),
      },
      getSuggestions: debounce(async function (query, loading) {
        //this method is part of data as each component needs a unique copy of the method to run
        loading(true);
        try {
          let variables = {
            query: query.trim(),
          };
          if (this.schema.queryVariables) {
            variables = { ...variables, ...this.fieldQueryVariables };
          }
          const { data } = await this.$apollo.query({
            query: this.schema.gqlQuery,
            variables,
          });
          if (this.schema.nodesFromData) {
            this.options = this.schema.nodesFromData(data);
          } else if (data[this.schema.queryName]?.edges) {
            this.options = data[this.schema.queryName].edges.map(
              ({ node }) => node
            );
          } else {
            throw new Error(
              "Please use nodesFromData() mapper for non-connection-of-edges style queries in id-selector"
            );
          }
        } catch (err) {
          console.log(err);
        } finally {
          loading(false);
        }
      }, 500),
    };
  },
  watch: {
    fieldQueryVariables() {
      this.getSuggestions("", () => null);
    },
  },
  computed: {
    isSelectedVisible() {
      return Boolean(this.schema.showSelected !== false && this.value);
    },
    preview() {
      return this.schema?.getPreview
        ? this.schema.getPreview(this.value)
        : null;
    },
    fieldQueryVariables() {
      return this.schema?.queryVariables?.();
    },
    isPolicy() {
      return this.schema?.queryVariables?.()?.filter?.isPolicy;
    }
  },
  async created() {
    await this.getSuggestions("", () => null);
  },
  methods: {
    async getSearchResults(query, loading) {
      await this.getSuggestions(query, loading);
    },
  },
};
</script>
