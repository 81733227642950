<template lang="pug">
  .row.mx-0.px-0
    .link.text-left.pb-1.px-0(v-if="schema.fieldLabel", :class="'col-12'")
      template(v-if="schema.chip && schema.chip.text")
        div.d-flex.justify-content-between.align-items-center
          span.field-label(:class="'font-sm'") {{ schema.fieldLabel }}
          nova-chip( :variant="schema.chip.variant") {{ schema.chip.text }}
      template(v-else)
        span(:class="'font-sm'") {{ schema.fieldLabel }}
    vue-select.inline-select-dropdown.w-100(
      :taggable="schema.taggable",
      v-model="value",
      :options="options",
      :disabled="disabled",
      :placeholder="schema.placeholder",
      :filterable="true",
      :searchable="schema.searchable",
      :reduce="schema.reduce",
      :readonly="schema.readonly",
      :multiple="schema.multiple",
      :components="{OpenIndicator, Deselect}")
      template(v-slot:option="opt")
        | {{opt.label || opt}}&nbsp;

      template(#selected-option="{ label }")
        .d-inline-block.h-100.font-sm.px-2.py-0(v-if="schema.imageIcon")
          .text-center.vue-select-image-icon(:class="'icon-'+schema.imageIcon")
        span.selected-text(
          :class="value==='None selected'?`text-gray-600 font-weight-medium`:''") {{ label }}

      template(#search="{ attributes, events }")
        input(
          :class="['vs__search', schema.imageIcon ? 'offest-cursor': '']",
          v-bind="attributes",
          v-on="events")

</template>

<script>
import VueSelect from "vue-select";
import { abstractField } from "vue-form-generator";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    VueSelect,
  },
  mixins: [abstractField],
  data() {
    return {
      options: [],
      OpenIndicator: {
        render: (createElement) =>
          createElement("span", { class: { "icon-chevron-down": true } }),
      },
      Deselect: {
        render: (createElement) => createElement("span", ""),
      },
    };
  },
  watch: {
    value() {
      if (!this.value) {
        // handling single and multi value variations
        this.value = this.schema.multiple ? [] : "None selected";
      }
      if (this.schema.transform && typeof this.value === "object") {
        this.value = this.schema.transform(this.value);
      }
    },
    "schema.options": function(){
      this.options = this.schema.options;
    }
  },
  async created() {
    this.options = this.schema.options;
    if (this.model && !this.model[this.schema.model]) {
      this.value = this.schema.multiple ? [] : "None selected";
    }
  },
};
</script>

<style lang="scss"></style>
