import { groupBy } from "lodash-es";

export const genderList = [
  { id: "female", name: "Female" },
  { id: "male", name: "Male" },
  { id: "other", name: "Other" },
];
export const dependentRelationList = [
  { id: "child", name: "Child" },
  { id: "parent", name: "Parent" },
  { id: "parent-in-law", name: "Parent-in-law" },
  { id: "sibling", name: "Sibling" },
  { id: "spouse", name: "Spouse" },
];
export default {
  isDependentRow(data) {
    return data.item.user_type === "dependent";
  },
  getMissingDataForMember(member, isUser = true) {
    const missingFields = [];
    // check for profile fields
    const requiredPersonalInfoFields = ["name", "gender", "dob"];
    if (!isUser) {
      requiredPersonalInfoFields.push("relation");
      if (member.user_type !== "placeholder") {
        member.gender = member.dep_gender;
        member.dob = member.dep_dob;
      }
    }

    requiredPersonalInfoFields.forEach((field) => {
      if (!member[field]) {
        missingFields.push(field);
      } else {
        // TODO: Outsource this validation logic to/from vfg validators
        // for identical fields
        switch (field) {
          case "gender":
            if (!genderList.find((genderObj) => genderObj.id === member[field])) {
              missingFields.push(field);
            }
            break;
          case "relation":
            if (!dependentRelationList.find((relationObj) => relationObj.id === member[field])) {
              missingFields.push(field);
            }
            break;
        }
      }
    });

    if (isUser) {
      // Employee specific validation
      const requiredEmployeeMetaFields = [
        "employeeId",
        "dateOfJoining",
        // TODO: Confirm if contactNumber is a required field
        // "contactNumber",
      ];
      requiredEmployeeMetaFields.forEach((field) => {
        if (!member.userMeta[field]) {
          missingFields.push(field);
        }
      });
    }

    return missingFields;
  },
  getUserChangeIds(userChangeData, rawData = false) {
    const ids = [];

    if (rawData) {
      userChangeData.forEach(
        (change) =>
          change.selected &&
          change.user_type !== "placeholder" &&
          change.meta.forEach((metaData) => ids.push(metaData.id))
      );
      return ids;
    }

    userChangeData.forEach((userChange) => {
      userChange.changes.forEach((change) => {
        if (change.selected && change.user_type !== "placeholder")
          change.meta.forEach((metaData) => ids.push(metaData.id));
      });
    });
    return ids;
  },
  getUserChangeStringForUpdate(data) {
    return Object.keys(data?.user_obj || {})
      .map((value) => value.toUpperCase())
      .join(",");
  },
};

export function mapBenefitsToOrgGrades(org) {
  const orgGrades = {};
  org.meta?.grades?.forEach((grade) => {
    orgGrades[grade] = [];
  });
  // safety check till grade mapping is fully tested
  if (Object.keys(orgGrades).length === 0) orgGrades["DEFAULT"] = [];
  org.benefits.forEach((benefit) => {
    for (let [grade, gradeMapping] of Object.entries(benefit.meta.gradeMapping)) {
      // if the grade is named default, add the benefit as a default grade
      if (grade.toUpperCase() === "DEFAULT") grade = grade.toUpperCase();
      pushBenefitsToGrade(benefit, orgGrades, grade, gradeMapping);
    }
  });

  // push the benefits to the grade based on the grade type and mapping
  function pushBenefitsToGrade(benefit, orgGrades, grade = "DEFAULT", gradeMapping = {}) {
    // if the benefit is of type policy
    if (benefit.node.isPolicy)
      orgGrades[grade].push({
        benefitName: benefit.node.type,
        amount: !benefit.meta?.isVariableCtc
          ? gradeMapping?.si
          : `${gradeMapping?.variableSiFactor}${gradeMapping?.variableSiText}`,
        familyDef: gradeMapping?.familyDefinition,
        isPolicy: benefit.node.isPolicy,
        endDate: benefit.node.meta.endDate,
      });
    // if the benefit is of type benefit
    else
      orgGrades[grade].push({
        benefitName: benefit.node.name,
        familyDef: gradeMapping?.familyDefinition,
        isPolicy: benefit.node.isPolicy,
        type: benefit.node.type,
      });
  }
  return orgGrades;
}
export function groupSegmentsByType(segments) {
  return groupBy(segments, (arrObj) => {
    return arrObj.node?.type;
  });
}
