<template lang="pug">
  n-inline-input.pt-1(
    v-model="value",
    v-bind="schema",
    :class="{'pb-3': !schema.noPadding}",
    :state="errors.length>0 ? 'error':''",
    :validationMessage="errors.length>0 ? errors[0]:''")
</template>

<script>
import NInlineInput from "@/components/NovaInlineInput.vue";
import { abstractField } from "vue-form-generator";

export default {
  components: {
    NInlineInput,
  },
  mixins: [abstractField],
};
</script>
