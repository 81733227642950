import { FieldObject } from "vfg-field-object";
import { ToggleButton } from "vue-js-toggle-button";
import VueElementLoading from "vue-element-loading";

import FormContainer from "@/components/Inputs/formContainer.vue";
import FieldIdSelector from "@/components/Inputs/fieldIdSelector.vue";
import FieldEditableDropdown from "@/components/Inputs/fieldEditableDropdown.vue";
import FieldInlineInput from "@/components/Inputs/fieldInlineInput.vue";
import FieldInlineSelect from "@/components/Inputs/fieldInlineSelect.vue";
import FieldFilesUploader from "@/components/Inputs/fieldFilesUploader.vue";
import FieldSelectCards from "@/components/Inputs/fieldSelectCards.vue";
import FieldMultiSelectCards from "@/components/Inputs/fieldMultiSelectCards.vue";
import ComboDatepicker from "@/components/Inputs/comboDatepicker.vue";
import FieldDatepicker from "@/components/Inputs/fieldDatepicker.vue";
import FieldDateRange from "@/components/Inputs/fieldDateRange.vue";
import FieldTimepicker from "@/components/Inputs/fieldTimepicker.vue";
import CTABanner from "@/components/CTABanner";
import Alerts from "@/components/Alerts";
import NIcon from "@/components/NovaIcon.vue";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("n-alerts", Alerts);
    Vue.component("n-cta-banner", CTABanner);
    Vue.component("form-container", FormContainer);
    Vue.component("field-id-selector", FieldIdSelector);
    Vue.component("field-editable-dropdown", FieldEditableDropdown);
    Vue.component("field-inline-input", FieldInlineInput);
    Vue.component("field-inline-select", FieldInlineSelect);
    Vue.component("field-select-cards", FieldSelectCards);
    Vue.component("field-files-uploader", FieldFilesUploader);
    Vue.component("field-multi-select-cards", FieldMultiSelectCards);
    Vue.component("combo-datepicker", ComboDatepicker);
    Vue.component("field-datepicker", FieldDatepicker);
    Vue.component("field-date-range", FieldDateRange);
    Vue.component("field-timepicker", FieldTimepicker);
    Vue.component("field-object", FieldObject);
    Vue.component("toggle-button", ToggleButton);
    Vue.component("n-spinner", VueElementLoading);
    Vue.component("n-icon", NIcon);
  },
};

export default GlobalComponents;
