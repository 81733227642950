<template lang="pug">
.row.mx-0.py-md-4.my-md-4

  .d-none.d-md-flex.col-md-7(v-if="imagePos=='left'")
    .col-12.row
      .col-10.col-xl-8.px-0
        .rounded-animation(:id="`article-animation-${blog.id}`")
      .col-2.col-xl-4

  .col-12.col-md-5
    br
    span.font-hc.text-teal-800 {{ blog.tags[0].name }}
    br
    br
    h1.hb1.d-none.d-lg-block {{ blog.meta_title }}
    h1.hb2.d-none.d-md-block.d-lg-none {{ blog.meta_title }}
    h4.hb4.d-block.d-md-none {{ blog.meta_title }}
    br
    .row.mx-0.text-gray-700
      .row.mx-0.align-items-center.pr-4.width-max-content
        span.icon.icon-user-circle.pr-2
        span.align-middle {{ blog.authors[0].name }}
      .row.mx-0.align-items-center.pr-4.width-max-content
        span.icon.icon-clock.pr-2
        span.align-middle {{ blog.reading_time }} min{{blog.reading_time!==1?'s':''}}
    br
    br
    .d-block.d-md-none.pb-4.m-sm-4
      .mr-4.my-4.rounded-animation.pb-4(:id="`article-animation-mobile-${blog.id}`")
    .font-weight-medium.break-spaces {{ blog.meta_description }}
    br
    br
    br
    n-button(
      :href="blog.url",
      target="_blank",
      variant="outline-dark",
      block,
      buttonText="Continue reading",
      rightImageIcon="chevron-right")

  .d-none.d-md-block.col-7(v-if="imagePos=='right'")
    .col-12.row.ml-3
      .col-2.col-xl-4
      .col-10.col-xl-8.px-0
        .rounded-animation(:id="`article-animation-${blog.id}`")

  .d-block.d-md-none.col-12(v-if="index != length-1")
    hr

</template>

<script>
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";

export default {
  name: "FeaturedBlog",
  components: {
    NButton,
  },
  props: {
    imagePos: {
      type: String,
      default: "left",
    },
    blog: {
      type: Object,
      default: function () {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    setTimeout(() => {
      if (utils.mobileCheck()) {
        window.lottie.loadAnimation({
          container: document.getElementById(
            "article-animation-mobile-" + this.blog.id
          ),
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: require("@/assets/animations/" +
            this.getMobileAnimation()),
        });
      } else {
        window.lottie.loadAnimation({
          container: document.getElementById(
            "article-animation-" + this.blog.id
          ),
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: require("@/assets/animations/" + this.getAnimation()),
        });
      }
    }, 5000);
  },
  beforeDestroy() {
    window.lottie.destroy();
  },
  methods: {
    getAnimation() {
      switch (this.index) {
        case 0: {
          return "ArticleOne/articleone.json";
        }
        case 1: {
          return "ArticleTwo/article2.json";
        }
        case 2: {
          return "ArticleThree/timeline.json";
        }
      }
    },
    getMobileAnimation() {
      switch (this.index) {
        case 0: {
          return "ArticleOne/articleonemobile.json";
        }
        case 1: {
          return "ArticleTwo/articletwomobile.json";
        }
        case 2: {
          return "ArticleThree/articlethreemobile.json";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.left-section {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.text-teal-800 {
  color: $teal-800;
}
.icon {
  font-size: 1.5rem;
}
.img-lg {
  width: 100%;
  top: 0;
  left: 0;
}
hr {
  margin-top: 50px;
  border: 1px solid $gray-400;
}
.width-max-content {
  width: max-content;
}
</style>

<style>
.rounded-animation > svg {
  border-radius: 0.5rem;
  width: 100%;
}
</style>
