<template lang="pug">
  div
    .row.select-cards-main-container(v-if="cardsData.length > 0")
      .col-sm-6.mt-2.full-width-cards(
        :class="`${cardType}-style`",
        v-for="card in cardsData",
        :style="cardType==='small'?('flex-basis: '+ 100/cardsData.length +'%') : ''")
        SelectCard(
          :cardType="cardType",
          :icon="card.icon",
          :iconColor="card.iconColor",
          :svg="card.svg",
          :cardValue="card.card_value",
          :label="card.label",
          :name="card.name",
          :description="card.description",
          :cost="card.cost",
          :value="activeState",
          @input="changeCtrlValue",
          :disabled="disabled",
          :readOnly="readOnly")
    .input-sub-text.text-left.pt-2(v-if="subText")
      span {{ subText }}
</template>

<script>
import SelectCard from "./SelectCard";
export default {
  name: "SelectCards",
  components: {
    SelectCard,
  },
  props: {
    /* eslint-disable-next-line vue/require-prop-types, vue/require-default-prop */
    value: {},
    cardType: {
      type: String,
      default: "small",
    },
    cardsData: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    subText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeState: "",
    };
  },
  watch: {
    value() {
      this.activeState = this.value;
    },
  },
  mounted() {
    this.activeState = this.value;
  },
  methods: {
    changeCtrlValue(cardValue) {
      if (!(this.readOnly || this.disabled)) {
        this.activeState = cardValue;
        this.$emit("input", cardValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-cards-main-container {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.small-style {
  // flex-basis: 25%;
  min-height: 100%;
}
.medium-style,
.large-style {
  flex-basis: 50%;
  margin-bottom: 0.8rem;
  min-height: 100%;
}

@media (max-width: 768px) {
  .medium-style,
  .large-style {
    flex-basis: 100%;
  }
  .small-style {
    flex-basis: 50% !important;
    min-height: 100%;
  }
}
</style>
