<template lang="pug">
.row.mx-0.px-0
  .link.text-left.pb-1.px-0(v-if="fieldLabel", :class="'col-12'")
    template(v-if="chip && chip.text")
      .d-flex.justify-content-between.align-items-center
        span.field-label(:class="'font-sm'") {{ fieldLabel }}
        nova-chip(:variant="chip.variant") {{ chip.text }}
    template(v-else)
      span(:class="'font-sm'") {{ fieldLabel }}
  b-input-group(:class="state")
    b-form-input(
      :id="id",
      v-model="displayValue",
      :placeholder="placeholder || `DD-MM-YYYY`",
      type="text",
      :formatter="formatter",
      lazy-formatter,
      @keydown.enter="onEnter",
      :disabled ="disabled",
      :readonly="readonly",
      :required="required")
    b-input-group-append
      b-form-datepicker(
        locale="en-GB",
        v-bind:value="value",
        :disabled="disabled || readonly",
        :required="required",
        :readonly="readonly",
        :date-format-options="{}",
        :hide-header="true",
        :show-decade-nav="true",
        button-only,
        button-variant="outline-secondary",
        @context="onContext",
        :min="min",
        :max="max")
  .d-block.input-sub-text.text-left.py-2(v-if="subText")
    span.py-5 {{ subText }}
  .input-sub-text.text-left.pt-2(v-if="validationMessage", :class="state")
    n-icon.pr-1.sub-text-icon.align-middle(v-if="state && state !== 'disabled'", name="error", :size="1")
    span {{ validationMessage }}
</template>

<script>
import moment from "moment";
import NovaChip from "../NovaChip.vue";
// TODO: Confirm we have suitable types
export default {
  components: {
    NovaChip,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: "1900-01-01",
    },
    max: {
      type: String,
      default: "2100-01-01",
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    subText: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: null,
    },
    chip: {
      type: Object,
      default: () => ({}),
    },
    fieldLabel: {
      type: String,
      default: null,
    },
    state: {
      type: String,
      default: null,
    },
    validationMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      displayValue: "",
    };
  },
  methods: {
    onContext(ctx) {
      const value = ctx.selectedYMD;
      if (value) this.displayValue = ctx.selectedFormatted;
      else this.displayValue = null;
      this.$emit("input", value);
    },
    onEnter(e) {
      this.displayValue = this.formatter(this.displayValue);
    },
    formatter(val) {
      const date = moment(val, "DD/MM/YYYY");
      if (!date.isValid()) {
        this.$emit("input", null);
        return null;
      }
      let value = date.toDate();
      if (this.min) {
        const minDate = moment(this.min).toDate();
        if (value < minDate) value = minDate;
      }
      if (this.max) {
        const maxDate = moment(this.max).toDate();
        if (value > maxDate) value = maxDate;
      }
      this.$emit("input", date.format("YYYY-MM-DD"));
      return date.format("DD/MM/YYYY");
    },
  },
};
</script>
