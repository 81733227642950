<template lang="pug">
  div
    vue-select.inline-select-dropdown(
      :taggable="schema.taggable",
      v-model="value",
      :options="options",
      label="name",
      value-attribute="id",
      :disabled="disabled",
      :placeholder="schema.placeholder",
      :filterable="false",
      @search="getSuggestions",
      :readonly="schema.readonly",
      :components="{OpenIndicator, Deselect}",
      ref="vueSelect")
      template(v-slot:option="opt")
        | {{opt.name}}&nbsp;
        span.text-muted.small(v-if="schema.getSubTitle") {{schema.getSubTitle(opt)}}

      template(#selected-option="{ name }")
        .d-inline-block.h-100.font-sm.px-2.py-0(v-if="schema.imageIcon")
          .text-center.vue-select-image-icon(:class="'icon-'+schema.imageIcon")
        span.selected-text(
          :class="(value === 'None selected' || value.name==='None selected')?`text-gray-600 font-weight-medium`:''")
          | {{ name }}

      template(#search="{ attributes, events }")
        input(:class="['vs__search', schema.imageIcon ? 'offest-cursor': '']", v-bind="attributes", v-on="events")
</template>

<script>
import VueSelect from "vue-select";
import { abstractField } from "vue-form-generator";

import "vue-select/dist/vue-select.css";

export default {
  components: {
    VueSelect,
  },
  mixins: [abstractField],
  data() {
    return {
      options: [],
      selected: false,
      OpenIndicator: {
        render: (createElement) =>
          createElement("span", { class: { "icon-chevron-down": true } }),
      },
      Deselect: {
        render: (createElement) => createElement("span", ""),
      },
    };
  },
  computed: {
    isSelectedVisible() {
      return Boolean(this.schema.showSelected !== false && this.value);
    },
  },
  watch: {
    value() {
      if (!this.value) {
        this.value = { name: "None selected" };
      }
      if (this.schema.transform && typeof this.value === "object") {
        this.value = this.schema.transform(this.value);
      }
    },
  },
  async created() {
    await this.getSuggestions("", () => null);
    if (this.model && !this.model[this.schema.model]) {
      this.value = "None selected";
    }
    const selectComponent = this.$refs.vueSelect;
    selectComponent.$on("option:selected", () => {
      this.selected = true;
    });
    selectComponent.onSearchBlur = () => {
      if (selectComponent.mousedown && !selectComponent.searching) {
        selectComponent.mousedown = false;
      } else {
        const { clearSearchOnSelect, multiple } = selectComponent;
        if (
          selectComponent.clearSearchOnBlur({ clearSearchOnSelect, multiple })
        ) {
          if (!this.selected) {
            selectComponent.updateValue(selectComponent.search);
          }
          this.selected = false;
          selectComponent.search = "";
        }
        selectComponent.closeSearchOptions();
        return;
      }
      if (
        selectComponent.search.length === 0 &&
        selectComponent.options.length === 0
      ) {
        selectComponent.closeSearchOptions();
      }
    };
  },
  methods: {
    async getSuggestions(query, loading) {
      loading(true);
      let variables = null;
      const additionalVariables = this.schema.additionalVariables
        ? this.schema.additionalVariables()
        : null;
      if (additionalVariables) {
        variables = {
          ...additionalVariables,
          query: query.trim(),
        };
      } else {
        variables = {
          query: query.trim(),
        };
      }
      try {
        const { data } = await this.$apollo.query({
          query: this.schema.gqlQuery,
          variables,
        });
        if (this.schema.nodesFromData)
          this.options = this.schema.nodesFromData(data);
        else if (data[this.schema.queryName].edges)
          this.options = data[this.schema.queryName].edges.map(
            ({ node }) => node
          );
        else {
          this.options = data[this.schema.queryName].map((template) => {
            return {
              id: template.id,
              name: template.name,
            };
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        loading(false);
      }
    },
  },
};
</script>

<style lang="scss"></style>
