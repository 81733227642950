import Vue from "vue";
import Vuex from "vuex";
import uuid from "uuid";

Vue.use(Vuex);
export const store = new Vuex.Store({
  state: {
    alerts: [],
    alertsCountMap: {},
    prospectId: "",
    scrollPositions: {},
    showAnnouncement: true,
    showVersioningModal: false,
  },
  getters: {
    isLoggedIn() {
      return false;
    },
    getAlertsCount(state) {
      return (message) => state.alertsCountMap[message]?.count || 0;
    },
    isAnnouncementShown(state) {
      return state.showAnnouncement;
    },
    showVersioningModal(state) {
      return state.showVersioningModal;
    },
  },
  actions: {
    async loadLocalStore(ctx) {
      const prospectId = localStorage.getItem("prospectId");
      if (prospectId) {
        ctx.state.prospectId = prospectId;
        return true;
      }
      return false;
    },
  },
  mutations: {
    saveProspectId(state, prospectId) {
      state.prospectId = prospectId;
      localStorage.setItem("prospectId", prospectId);
    },
    addAlert(state, { message, variant, id }) {
      if (!id) id = uuid();
      const oldAlert = state.alerts.find((alert) => alert.message === message);
      if (oldAlert) Object.assign(oldAlert, { variant, message });
      else state.alerts.push({ variant, message, id });
      if (!state.alertsCountMap[message]) {
        Vue.set(state.alertsCountMap, message, { count: 0 });
      }
      state.alertsCountMap[message].count++;
      return id;
    },
    clearAlert(state, id) {
      const idx = state.alerts.findIndex((alert) => alert.id === id);
      state.alertsCountMap[state.alerts[idx].message].count = 0;
      state.alerts.splice(idx, 1);
    },
    clearAlerts(state) {
      state.alerts = [];
      state.alertsCountMap = {};
    },
    changeScrollPosition(state, { page, scrollPosition }) {
      Vue.set(state.scrollPositions, page, scrollPosition);
    },
    closeAnnouncement(state) {
      state.showAnnouncement = false;
    },
    enableVersioningModal(state) {
      state.showVersioningModal = true;
    },
  },
});
