import moment from "moment";

const IND_MOBILE_NUMBER_REGEXP = /^[6-9]\d{9}$/;
export default {
  validateIndianMobileNumbers(number) {
    return number.toString().match(IND_MOBILE_NUMBER_REGEXP);
  },
  validateIndianMobileNumbersForVfg(num) {
    if (!num || num.toString().match(IND_MOBILE_NUMBER_REGEXP)) return [];
    return ["Invalid phone number!"];
  },
  // This function is a validator for VFG. That fixes the validation issue with vfg nested fields
  // To use this make sure validateAsync is true in form options.
  async validateChildFields(value) {
    const errors = [];
    const nestedVFG = this.$children[0];
    await nestedVFG.validate();
    if (nestedVFG.errors.length > 0) {
      // This error message wont be visible anywhere
      errors.push("Subfields are invalid!");
    }
    return errors;
  },
  validateInlineSelect(value) {
    if (value === "None selected") return ["None Selected"];
    return [];
  },
  validateDateOfAdmissionDischargeForVfg(dod, _self, formValues) {
    if (!dod) return [];
    if (!formValues.doa) return ["Add Date of Admission first"];
    if (moment(dod).isBefore(moment(formValues.doa))) return ["Date of Discharge can't be before Date of Admission"];
    return [];
  },
};

export function validateEmployeeAge(dob) {
  if (!dob) return [];
  if (moment(dob).isAfter(moment().subtract(18, "years"))) return ["Employee must be at least 18 years of age."];
  return [];
}

export function validateDependentAge(dob, _self, formValues) {
  if (!dob || !formValues.userDob) return [];
  if (formValues.relation === "spouse" && moment(dob).isAfter(moment().subtract(18, "years")))
    return ["Spouse must be at least 18 years of age."];
  if (formValues.relation === "child" && moment(dob).diff(moment(formValues.userDob), "years") < 15)
    return ["Child has to be at least 15 years younger than you."];
  if (
    ["parent", "parent-in-law"].includes(formValues.relation) &&
    moment(formValues.userDob).diff(moment(dob), "years") < 15
  )
    return ["Parent/Parent-in-law should be at least 15 years older than you."];
  return [];
}
