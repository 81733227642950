<template lang="pug">
div
  n-toast(
    v-for="item in $store.state.alerts",
    :variant="item.variant",
    :key="item.id",
    :id="item.id",
    @hidden="alertDismissed(item.id)")
    template(v-slot:toast-title)
      .alert-title
        span {{getTitle(item)}}
        n-chip(v-if="errorCount(item.message) > 1", :variant="item.variant", pill) {{errorCount(item.message)}}
    template(v-slot:toast-content)
      span.align-middle  {{ item.message }}
</template>

<script>
import NChip from "@/components/NovaChip.vue";
import NToast from "../components/NovaToast.vue";

export default {
  components: {
    NChip,
    NToast,
  },
  methods: {
    alertDismissed(id) {
      this.$store.commit("clearAlert", id);
    },
    getTitle(item) {
      if (item.variant === "danger") return "Oops!";
      if (item.variant === "success") return "Success";
      if (item.variant === "warning") return "Important note";
      return "Notification";
    },
    errorCount(message) {
      return this.$store.getters.getAlertsCount(message);
    },
  },
};
</script>

<style scoped lang="scss">
.alert-title {
  display: flex;
  gap: 0.5rem;
}
</style>
