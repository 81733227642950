<template lang="pug">
  .border.rounded.my-2.container-fluid
    b-link(v-b-toggle="`${baseID}-item`")
      .row.bg-light
        .col.p-2
          span {{ title }}
          .p-2.position-absolute(style="right: 0; top: 0")
            b-link.p-1(title="Move Up", @click="emitEvent('moveItemUp', $event)")
              i.fa.fa-arrow-up
            b-link.p-1(title="Move Down", @click="emitEvent('moveItemDown', $event)")
              i.fa.fa-arrow-down
            b-link.p-1(title="Remove", @click="emitEvent('removeItem', $event)")
              i.fa.fa-close
    b-collapse(:id="`${baseID}-item`", visible, appear)
      .content.m-2
        slot
        div(v-html="preview")
</template>

<script>
import uuid from "uuid";
export default {
  name: "FormContainer",
  inheritAttrs: false,
  props: {
    model: {
      type: Object,
      default: null,
    },
    baseID: {
      type: String,
      default: uuid,
    },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      if (this.$attrs.schema.getTitle)
        return this.$attrs.schema.getTitle(this.$attrs.index, this.model);
      return `Item ${this.$attrs.index + 1}`;
    },
    preview() {
      if (this.$attrs.schema.getPreview && this.model)
        return this.$attrs.schema.getPreview(this.$attrs.index, this.model);
      return "";
    },
  },
  methods: {
    emitEvent(newEvent, event) {
      event.stopPropagation();
      event.preventDefault();
      this.$emit(newEvent);
    },
  },
};
</script>
