<template lang="pug">
combo-datepicker(
  v-model="value",
  :id="schema.id",
  :max="schema.max",
  :min="schema.min",
  :disabled="schema.disabled",
  :readonly="schema.readonly",
  :required="schema.required",
  :subText="schema.subText",
  :fieldLabel="schema.fieldLabel",
  :chip="schema.chip",
  :placeholder="schema.placeholder",
  :state="errors.length>0 ? 'error':''",
  :validationMessage="errors.length>0 ? errors[0]:''")
</template>

<script>
import { abstractField } from "vue-form-generator";

import comboDatepicker from "./comboDatepicker.vue";

export default {
  components: {
    comboDatepicker,
  },
  mixins: [abstractField],
};
</script>
