<template lang="pug">
  .example-full.bg-blue-100.p-2.position-relative.pb-5
    .drop-active(v-show="$refs.upload && $refs.upload.dropActive")
      h3 Drop files to upload
    div(v-if="!value.length && !files.length")
      .text-center.p-5
        h4
          | Drop files anywhere to upload
          br
          | or
        label.btn.btn-lg.btn-outline-dark.py-1(:for="name") Select Files
    .upload(v-show="files.length || value.length")
      n-spinner(:active="loading", background-color="rgba(255,255,255,0.5)")
      .d-flex.justify-content-between.align-items-center.border-bottom.pb-2.px-2.pt-2
        span.font-md.font-weight-semibold {{ value.length }} Files Uploaded
        .d-flex
          .btn.btn-outline-dark.d-inline-flex.align-items-center(@click="downloadZip")
            i.icon-download
          file-upload.btn.btn-outline-dark.d-inline-flex.align-items-center(
            ref="upload",
            :extensions="extensions",
            :disabled="disabled",
            :accept="accept",
            :multiple="multiple",
            :directory="directory",
            :size="size || 0",
            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread",
            :drop="drop",
            :drop-directory="dropDirectory",
            v-model="files",
            @input-filter="inputFilter",
            @input-file="inputFile")
            i.icon-attachment.mr-2
            span Upload Files
      .row(v-for="(File, index) in files", :key="File.id")
        .col-md-2.pt-3.text-center
          img.img-fluid.mx-auto(
            v-if='File.thumb || File.type === "image"'
            :src='File.thumb?File.thumb:File.url' width="60px")
          img.img-fluid.mx-auto.ml-2(
            v-else-if='File.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"||"text/csv"',
            :src="require('@/assets/images/xl-placeholder.svg')",
            :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
          img.img-fluid.mx-auto.ml-2(
            v-else-if="File.type === 'pdf'",
            :src="require('@/assets/images/placeholder-pdf.svg')",
            :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
          img.img-fluid.mx-auto.ml-2(
            v-else :src="require('@/assets/images/placeholder-zip.svg')",
            :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
        .col-md-10.pt-3
          .row
            .col-10
              .filename.text-truncate
                |  {{ File.name }}
            .col-2.text-right(v-if="!rawOutput")
              i.icon-eye.cursor-pointer.mr-2(@click.prevent="preview(File.blob)")
              i.icon-delete.cursor-pointer(@click.prevent="deleteFile(File)")
          .progress.mt-2(v-if="File.active || File.progress !== '0.00'", :style="{height:'7px'}")
                  div(:class="{\
                  'progress-bar': true,\
                  'progress-bar-striped': false,\
                  'bg-danger': File.error,\
                  'bg-teal-600': File.progress == '100',\
                  'progress-bar-animated': File.active,\
                  }" role='progressbar' :style="{ height: '7px', width: File.progress + '%' }")

      template(v-if="!rawOutput")
        .row.align-items-center(v-for='(File, index) in value' :key='File.id')
          .col-md-2.pt-3.text-center
            img.img-fluid.mx-auto.ml-2(v-if='File.type === "image"', :src='File.url', :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px', objectFit: 'cover'}")
            img.img-fluid.mx-auto.ml-2(v-else-if="File.type === 'pdf'", :src="require('@/assets/images/placeholder-pdf.svg')" :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
            img.img-fluid.mx-auto.ml-2(v-else :src="require('@/assets/images/placeholder-zip.svg')", :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
          .col-md-10.pt-3
            .row.align-items-center
              .col-12.col-md-5.align-items-center
                .filename.text-truncate
                  |  {{ File.name }}
              .col-12.col-md-7.text-md-right.d-flex.d-md-block.justify-content-between.mt-2
                n-button.mr-2(rightImageIcon="chevron-down", variant="outline-secondary",pill, :buttonText="File.docType?File.docType:'Select Type'", type="button", @click="$bvModal.show(File.id)", size="sm", :disabled="disabled")
                b-modal(:id="File.id", no-footer, title="Upload Documents", hide-footer)
                  template(slot='modal-header')
                    .d-flex.justify-content-between.align-items-center.w-100
                      .py-1.d-flex.align-items-center
                        span.font-lg.font-weight-semibold.mr-2 Select Document Type
                      .py-1
                        b-avatar(variant="none", button, @click="$bvModal.hide(File.id)")
                          i.icon-cross
                  vue-select(:options="options", v-model="File.docType", @change="$bvModal.hide(File.id)" )
                  h5.h5.mt-3 Preview
                  .d-flex.justify-content-center.align-items-center.m-5(v-if="File.type === 'image'")
                    img.img-fluid.mx-auto.ml-2(:src="File.url", :style="{height: '200px',width:'auto', maxWidth: '200px', maxHeight: '200px'}")
                  iframe.w-100.mt-2(v-else-if="File.type === 'pdf'", :src="File.url" :style="{height: '370px'}")
                  .d-flex.justify-content-center.align-items-center.m-5(v-else)
                    img.img-fluid.mx-auto.ml-2(:src="require('@/assets/images/placeholder-zip.svg')", :style="{height: '200px',width:'auto', maxWidth: '200px', maxHeight: '200px'}")
                  .text-right
                    n-button.w-25.mt-2(variant="secondary", buttonText="Ok", @click="$bvModal.hide(File.id)")
                n-button(imageIcon="eye", variant="outline-secondary", title="Preview", size="sm", @click="preview(File.url)")
          .col-12
            hr.hr
</template>

<script>
import FileUpload from "vue-upload-component";
import VueSelect from "vue-select";
import gql from "graphql-tag";
import NButton from "./NovaButton.vue";
export default {
  components: {
    FileUpload,
    NButton,
    VueSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    uploadedFiles: {
      type: Array,
      default: function () {
        return [];
      },
    },
    accept: {
      type: String,
      default: "image/png,image/gif,image/jpeg,image/webp,text/pdf,zip,application/zip,pdf,application/pdf",
    },
    extensions: {
      type: String,
      default: "gif,jpg,jpeg,png,webp,pdf,doc",
    },
    uploadTo: {
      type: String,
      default: "gcp",
    },
    verifyUpload: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    rawOutput: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      uploadAuto: true,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      thread: 3,
      name: "file",
      isOption: false,
      loading: false,
      all_files: [...this.value],
      files: [],
    };
  },
  watch: {
    all_files: function () {
      this.$emit("input", this.all_files);
      this.files = [];
    },
    value: function () {
      if (this.rawOutput && this.value !== this.files) {
        this.files = this.value;
      }
    },
    verifyUpload: function () {
      if (this.verifyUpload > 0) {
        if (this.all_files.length === this.files.length) {
          this.$emit("success", [...this.uploadedFiles, ...this.all_files]);
          this.files = [];
        } else {
          this.$emit("failure");
        }
      }
    },
  },
  mounted() {
    this.all_files = [...this.value];
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.blob = "";
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile === undefined) {
        if (this.uploadAuto && !this.rawOutput) {
          this.sendtodb(newFile);
        }
        if (this.rawOutput) {
          this.files = [newFile];
          this.$emit("input", this.files);
        }
      }
    },

    startFakeProgress(newFile) {
      var step = Math.random(10) / 10;
      var currentProgress = 0;
      const interval = setInterval(function () {
        currentProgress += step;
        newFile.progress = Math.round((Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000) / 1000;
        if (newFile.progress >= 100) {
        } else if (newFile.progress >= 70) {
          step = 0.1;
        }
      }, 100);

      return interval;
    },
    failFakeProgress(newFile, progress) {
      clearInterval(progress);
      newFile.error = true;
    },
    completeFakeProgress(newFile, progress) {
      newFile.progress = "100";
      clearInterval(progress);
    },
    async sendtodb(newFile) {
      const progress = this.startFakeProgress(newFile);
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($file: Upload!, $meta: JSONObject) {
              upsertFile(input: { meta: $meta, upload: $file }) {
                file {
                  id
                  name
                  url
                  meta
                }
              }
            }
          `,
          variables: {
            file: newFile.file,
            meta: {
              type: this.getFileType(newFile),
            },
          },
        });

        this.all_files.unshift({
          type: result.data.upsertFile.file.meta.type,
          url: result.data.upsertFile.file.url,
          name: result.data.upsertFile.file.name,
          id: result.data.upsertFile.file.id,
        });

        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].file === newFile.file) {
            this.files[i].uploadId = result.data.upsertFile.file.id;
            break;
          }
        }
        this.completeFakeProgress(newFile, progress);
      } catch (err) {
        this.failFakeProgress(newFile, progress);
      }
    },
    getFileType(file) {
      let type = null;
      type = file.type.split("/")[0];
      if (type === "application") {
        type = file.type.split("/")[1] === "pdf" ? "pdf" : "zip";
      }
      return type;
    },
    deleteFile(File) {
      this.$refs.upload.remove(File);
      this.$emit("updated", this.all_files);
    },
    preview(url) {
      window.open(url, "_blank");
    },
    async downloadZip() {
      this.loading = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($meta: JSONObject) {
              zipFiles(input: { meta: $meta }) {
                file {
                  url
                }
              }
            }
          `,
          variables: {
            meta: {
              files: this.all_files,
            },
          },
        });
        const url = result.data.zipFiles?.file?.url;
        if (url) {
          window.open(url, "_blank");
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}
.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.example-full .hr {
  color: $gray-500;
  background-color: $gray-400;
  height: 1px;
}
</style>
