/* eslint-disable camelcase */
import gql from "graphql-tag";
import utils from "@/utils";

export const prospectFragment = gql`
  fragment Prospect on Prospect {
    id
    email
    pricing_table_state
    meta
    addons
  }
`;

export const prospectEventFragment = gql`
  fragment ProspectEvent on ProspectEvent {
    id
    prospect_id
    event_name
    event_params
    timestamp
  }
`;

export const strapiContentFragment = gql`
  fragment StrapiContent on StrapiContent {
    id
    slug
    dynamicContent
    meta
  }
`;

export const gqlResourceConfig = {
  Prospect: { plural: "prospects", fragment: prospectFragment },
  StrapiContent: { plural: "strapiContents", fragment: strapiContentFragment },
};

export const getSingleQuery = (gqlType) => {
  const { fragment } = gqlResourceConfig[gqlType];
  const query = gql`query ${gqlType}Single($id: ID!){
      node(id: $id){ ...${gqlType} }
    }
    ${fragment}`;
  return query;
};

export const getListQuery = (gqlType) => {
  const { plural, fragment } = gqlResourceConfig[gqlType];
  const query = gql`
  query ${gqlType}List(
      $query: String
      $offset: Int
      $limit: Int
      $filter: JSONObject
    ) {
      ${plural}(
        query: $query
        offset: $offset
        limit: $limit
        filter: $filter
        ) {
      edges {
        node { ...${gqlType} }
      }
      totalCount
    }
  }
  ${fragment}`;
  return query;
};

export const getCitiesQuery = () => {
  const query = gql`
    query DropdownCities($query: String) {
      dropdownCities(query: $query)
    }
  `;
  return query;
};

export default {
  prospects: {
    gqlType: "Prospect",
    singleQuery: getSingleQuery("Prospect"),
    transform: (n) => {
      return {
        ...n,
        organization: n.meta?.org_name || null,
        location: n.meta?.location || null,
        coverageType: n.meta?.coverage_type || null,
        employeeCount: n.meta?.employee_count || null,
        employeeAvgAge: n.meta?.employee_avg_age || null,
        industry: n.meta?.industry || null,
        phone: n.meta?.phone || null,
      };
    },
    upsertMutation: gql`
      mutation m0(
        $id: ID
        $email: String
        $link: String
        $pricingTableState: JSONObject
        $addons: [String]
        $meta: JSONObject
        $calculatePremiums: Boolean
      ) {
        upsertProspect(
          input: {
            id: $id
            email: $email
            link: $link
            pricingTableState: $pricingTableState
            addons: $addons
            meta: $meta
            calculatePremiums: $calculatePremiums
          }
        ) {
          prospect {
            ...Prospect
          }
        }
      }
      ${prospectFragment}
    `,
    eventMutation: gql`
      mutation m1(
        $id: ID
        $prospectId: ID
        $eventName: String
        $eventParams: JSONObject
      ) {
        upsertProspectEvent(
          input: {
            id: $id
            prospectId: $prospectId
            eventName: $eventName
            eventParams: $eventParams
          }
        ) {
          prospectEvent {
            ...ProspectEvent
          }
        }
      }
      ${prospectEventFragment}
    `,
  },
  workspace: {
    schema: {
      fields: [
        {
          model: "email",
          type: "inline-input",
          label: "Work email",
          subText:
            "Email is required to save your progress. You can make changes to your policy requirements at any point.",
          imageIcon: "at-the-rate",
          placeholder: "Enter you work mail here",
          validator: "email",
          required: true,
        },
        {
          model: "phone",
          type: "inline-input",
          label: "Phone number",
          imageIcon: "mobile",
          placeholder: "Enter you mobile number here",
          validator: ["required", utils.validateIndianMobileNumbersForVfg],
          inputType: "number",
          required: true,
        },
        {
          model: "location",
          type: "editable-dropdown",
          label: "Where is your registered office located?",
          imageIcon: "city",
          placeholder: "Select a city",
          gqlQuery: getCitiesQuery(),
          queryName: "dropdownCities",
          taggable: true,
          required: true,
        },
        {
          model: "industry",
          type: "inline-select",
          label: "Which industry do you operate in?",
          imageIcon: "work",
          options: [
            "Information Technology",
            "Electricals & Electronics",
            "Automobile",
            "Banking & Insurance",
            "Real Estate",
            "Other",
          ],
          required: true,
          searchable: false,
        },
      ],
    },
  },
  employees: {
    schema: {
      fields: [
        {
          model: "employeeCount",
          type: "inline-input",
          label: "How many Employees does your company have?",
          placeholder: "Enter Number of employees",
          imageIcon: "users",
          validator: "number",
          inputType: "number",
          required: true,
        },
        {
          model: "employeeAvgAge",
          type: "select-cards",
          label: "What’s the average age of your employees?",
          cardsData: [
            {
              name: "age-group",
              label: "<20",
              card_value: "<20",
            },
            {
              name: "age-group",
              label: "20-30",
              card_value: "20-30",
            },
            {
              name: "age-group",
              label: "30-40",
              card_value: "30-40",
            },
            {
              name: "age-group",
              label: ">40",
              card_value: ">40",
            },
          ],
          required: true,
        },
        {
          model: "coverageType",
          type: "select-cards",
          label: "What type of Insurance coverage are you looking for?",
          cardType: "medium",
          cardsData: [
            {
              name: "coverage",
              svg: "coverageESC",
              card_value: "ESC",
              label: "Employee, spouse & children",
            },
            {
              name: "coverage",
              svg: "coverageESCP",
              card_value: "ESCP",
              label: "Employee, spouse & children, parents",
            },
            {
              name: "coverage",
              svg: "coverageE",
              card_value: "E",
              label: "Employee only",
            },
          ],
          required: true,
        },
      ],
    },
  },
  customizePolicy: {
    schema: {
      fields: [
        {
          model: "suminsured",
          icon: "rupee",
          iconColor: "success",
          type: "select-cards",
          fieldLabel: "Sum Insured",
          cardsData: [
            {
              name: "sum-insured",
              label: "300000",
              card_value: "300000",
            },
            {
              name: "sum-insured",
              label: "500000",
              card_value: "500000",
            },
            {
              name: "sum-insured",
              label: "1000000",
              card_value: "1000000",
            },
          ],
          hint:
            "Sum insured is the maximum value for a year that your Insurance Company can pay in case you are hospitalized.\n\nAny amount above and beyond the sum insured will have to be taken out from your own pocket.",
          required: true,
        },
        {
          model: "normalroomrentlimit",
          icon: "bed",
          iconColor: "warning",
          type: "select-cards",
          fieldLabel: "Normal Room Rent Limit",
          cardsData: [
            {
              name: "normal-room-rent-limit",
              label: "1% of Sum Insured",
              card_value: "1% of Sum Insured",
            },
            {
              name: "normal-room-rent-limit",
              label: "2% of Sum Insured",
              card_value: "2% of Sum Insured",
            },
            {
              name: "normal-room-rent-limit",
              label: "No Limit",
              card_value: "No Limit",
            },
          ],
          hint:
            "The limit imposed on the coverage of boarding expenses or room rent of the hospital is called room rent limit.",
          required: true,
        },
        {
          model: "waitperiod",
          icon: "clock",
          iconColor: "info",
          type: "select-cards",
          fieldLabel: "Wait Period",
          cardsData: [
            {
              name: "wait-period",
              label: "2 year Wait",
              card_value: "2 year Wait",
            },
            {
              name: "wait-period",
              label: "No Wait",
              card_value: "No Wait",
            },
          ],
          hint: "The wait period.",
          required: true,
        },
        {
          model: "maternitybenefits-limitsandcoverages",
          icon: "pregnant",
          iconColor: "dark",
          type: "select-cards",
          fieldLabel: "Maternity Benefits - Limits and Coverages",
          cardsData: [
            {
              name: "maternity-benefits---limits-and-coverages",
              label: "Not Covered",
              card_value: "Not Covered",
            },
            {
              name: "maternity-benefits---limits-and-coverages",
              label: "30k+40k",
              card_value: "30k+40k",
            },
            {
              name: "maternity-benefits---limits-and-coverages",
              label: "60k+75k",
              card_value: "60k+75k",
            },
          ],
          hint: "Maternity benefits - limits and coverages subtext.",
          required: true,
        },
      ],
    },
  },
  addons: {
    schema: {
      fields: [
        {
          model: "addons",
          type: "multi-select-cards",
          label:
            "Would you like to offer any additional health and wellness benefits to your employees?",
          cardType: "large",
          cardsData: [
            {
              name: "addon",
              icon: "teeth",
              label: "Dental Care",
              description:
                "Access to best in class Dental facilities and coverage for checkups and surgical procedures",
              cost: "499",
            },
            {
              name: "addon",
              icon: "bandage",
              label: "Personal Accident",
              description:
                "The best-in-class insurance of 10L covering death, temporary, partial or permanent disability",
              cost: "599",
            },
            {
              name: "addon",
              icon: "heart",
              label: "Health Checkups",
              description:
                "Best preventive health check up packages with expert diagnosis and comprehensive tests",
              cost: "699",
            },
            {
              name: "addon",
              icon: "hospital",
              label: "OPD - Essentials",
              description:
                "Consulatations across the following specialities - Gynecology, Pediatrician, Orthopedic & Derma",
              cost: "799",
            },
          ],
          required: true,
          readonly: true,
        },
      ],
    },
  },
  renewalForm: {
    schema: {
      fields: [
        {
          model: "name",
          type: "inline-input",
          label: "Your Name",
          imageIcon: "user-circle",
          placeholder: "Please enter your full name here",
          validator: "string",
          required: true,
        },
        {
          model: "email",
          type: "inline-input",
          label: "Work email",
          subText: "No spam, we’ll use this email only for scheduling calls",
          imageIcon: "at-the-rate",
          placeholder: "Enter you work mail here",
          validator: "email",
        },
        {
          model: "phone",
          type: "inline-input",
          label: "Where can we call you?",
          placeholder: "Enter your mobile number here",
          imageIcon: "mobile",
          validator: ["required", utils.validateIndianMobileNumbersForVfg],
          inputType: "number",
          required: true,
        },
        {
          model: "employeeCount",
          type: "inline-input",
          label: "How many Employees does your company have?",
          placeholder: "Enter approximate number of employees",
          imageIcon: "users",
          validator: "number",
          inputType: "number",
          required: true,
        },
        {
          model: "role",
          type: "inline-select",
          label: "What is your role at the company?",
          imageIcon: "work",
          options: ["Founder", "HR", "Finance", "Other"],
          subText: "Please tell us about your primary role at the organization",
          searchable: false,
          required: true,
        },
        {
          model: "renewalDate",
          type: "datepicker",
          label: "Date of policy renewal",
          min: "1900-01-01",
          max: "2100-12-31",
          subText: "Optional, it's okay if you don't have this ready",
        },
      ],
    },
  },
  strapiContents: {
    gqlType: "StrapiContent",
    listQuery: getListQuery("StrapiContent"),
    singleQuery: getSingleQuery("StrapiContent"),
  },
};
