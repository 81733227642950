<template lang="pug">
    b-toast(
      visible, solid, append-toast, no-close-button,
      toast-class="nova-toast-wrapper"
      body-class="nova-toast p-0",
      auto-hide-delay="10000",
      toaster="b-toaster-bottom-right",
      @hide="closeToast",
      v-bind="{...$props, ...$attrs}",
      )
      .d-flex(@click="closeToast")
        .d-flex.px-3.toast-gap-6.justify-content-center(:class="$slots['toast-content'] ? 'py-3 align-items-top' : 'py-2 align-items-center'",)
          div(v-if="showIcon")
            span(:class="`toast-icon icon-${iconMap[variant]}`", :style="{color: iconColorMap[variant]}")
          div.d-flex.flex-column.toast-gap-3.justify-content-center
            .font-md.font-weight-semibold
              slot(name="toast-title").d-block
                | Toast Title
            p.font-sm.font-weight-medium.mb-0(v-if="$slots['toast-content']")
              slot(name="toast-content")
        n-button.btn-interactive.ml-2.rounded-0.border-0(
          v-if="interactive"
          :textUnderline="true"
          :buttonText="`${interactive}`" 
          rightImageIcon="chevron-right"
          to="https://www.getnovaapp.com")
          slot(name="interactive")
          span.icon-chevron-right

</template>

<script>
import NButton from "../components/NovaButton.vue";

export default {
  name: "NToast",
  components: {
    NButton,
  },
  props: {
    interactive: {
      type: String,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      iconMap: {
        danger: "error",
        warning: "warning",
        success: "check-circle",
      },
      iconColorMap: {
        danger: "#FA8787",
        warning: "#FFC847",
        success: "#7adad3",
      },
    };
  },
  methods: {
    closeToast() {
      this.$store.commit("clearAlert", this.$attrs.id);
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.nova-toast-wrapper {
  position: relative;
  right: 0.75rem;
}
.nova-toast {
  color: $white;
  border-radius: 0.375rem;
  background-color: $tiber;
  border: 2px solid $gray-900;
  /* max-width: fit-content;
        float: right; */

  .toast-interaction-divider {
    height: 100%;
    color: $gray-900;
  }

  .btn-interactive {
    background-color: $tiber;
    border-left: 2px solid $gray-900 !important;
    &:active {
      background-color: unset;
    }
  }

  .toast-icon {
    color: #7adad3;
    vertical-align: text-bottom;
  }

  .toast-gap-6 {
    gap: 0.6rem;
  }

  .toast-gap-3 {
    gap: 0.3rem;
  }
}

// Changing the bs toaster class as it fixes the width to 350px
.b-toaster-slot {
  max-width: max-content !important;

  .toast {
    border-radius: 0.375rem;
  }
}
</style>
