import "es6-promise/auto";
import moment from "moment";
import BootstrapVue from "bootstrap-vue";
import VueFormGenerator from "vue-form-generator";
import VfgFieldArray from "vfg-field-array";

import utils from "../utils";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

// css assets
import "@/assets/styles/custom.scss";

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);

    Vue.use(BootstrapVue);
    Vue.use(VueFormGenerator);
    Vue.use(VfgFieldArray);

    Vue.filter("date", (ts) => moment(ts).format("DD-MM-YYYY"));
    Vue.filter("longDate", (ts) => moment(ts).format("MMMM Do, YYYY"));
    Vue.filter("titleCase", (str) =>
      str.replace(
        /\w\S*/g,
        (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
      )
    );
    Vue.filter("inr", (x) => {
      if (typeof x === "string") {
        if (x.match(/^[\d,.]+$/)) x = parseFloat(x.replace(/,/g, ""));
        else return x;
      }
      return utils.toINR(x);
    });
  },
};
