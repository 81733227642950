<template lang="pug">
div
  .container.container-homepage.mt-4
    .row.mx-2.pt-4
      .col-12.offset-lg-1.col-lg-5.px-0
        .mt-4
          img.d-block.mx-auto.mx-lg-0(:src="require(`@/assets/emojis/wave.svg`)", width="40")
          br
          br
          h1.hb1.text-lg-left.d-none.d-xl-block.break-spaces
            | {{ "We help companies find affordable\nhealth insurance that their\nemployees can trust" }}
          h3.hb3.text-center.text-lg-left.d-xl-none.break-spaces
            | {{ "We help companies find\naffordable health insurance\nthat their employees can trust" }}
        br
        br
        div
          .font-weight-medium.text-gray-800.text-lg-left.d-none.d-xl-block.break-spaces.pb-2
            | {{ "Buying insurance for your company? Get a second opinion for free" }}
          .font-weight-medium.text-gray-800.text-center.text-lg-left.d-xl-none.break-spaces.pb-2
            | {{ "Buying insurance for your company?\nGet a second opinion for free" }}

          b-form(@submit.prevent="submitHeroPhoneNumber")
            div.col-12.d-none.d-xl-flex.flex-wrap.px-0.py-3.mx-auto
              img.hero-arrow.d-none.d-lg-block(:src="require(`@/assets/images/homepage-hero-arrow.svg`)")
              n-button.col-12.shadow-none(
                variant="success",
                buttonText="Get a quote ->",
                :to="{name:'getStartedWithCalculator'}")
              .row.col-12.align-itmes-center.justify-content-center.my-1.hero-divider.ml-0.px-0
                hr.mx-0
                span.pt-2.text-center.text-gray-800.font-weight-medium or
                hr.mx-0
              n-inline-input.col-7(
                :customClasses="['input-tiber', 'rounded-right-none']",
                type='email',
                v-model="heroPhone",
                name='email',
                :required='true',
                placeholder='+91 Drop your digits',
                imageIcon="mobile")
              n-button.col-5.rounded-left-none.py-1.shadow-none(
                variant="tiber",
                buttonText="Request Callback",
                imageIcon="phone")
          
            div.input-group.col-12.d-flex.d-xl-none.px-0.pt-3.mx-auto.mx-lg-0(style="padding-bottom: 100px;")
              n-button.col-12.shadow-none(
                variant="success",
                buttonText="Get a quote ->",
                :to="{name:'getStartedWithCalculator'}")
              .row.col-12.align-itmes-center.justify-content-center.my-1.hero-divider.ml-0.px-0
                hr.mx-0
                span.pt-2.px-1.text-center.text-gray-800.font-weight-medium or
                hr.mx-0
              n-inline-input.col-12.pb-0(
                :customClasses="['input-tiber', 'rounded-bottom-none']",
                type='email',
                v-model="heroPhone",
                name='email',
                :required='true',
                placeholder='+91 Drop your digits',
                imageIcon="mobile")
              n-button.col-12.px-3.mx-auto.d-block.d-xl-none.rounded-top-none(
                variant="tiber",
                buttonText="Request Callback",
                imageIcon="phone")
              img.hero-arrow-mobile.d-block.d-xl-none(
                :src="require(`@/assets/images/homepage-hero-arrow.svg`)")  
        br
        br
        div
          .font-hc.text-gray-500.text-center.text-lg-left Trusted By
          .logos-container.mx-0.my-3
            .row.flex-nowrap.logos.text-center
              img.py-2(
                v-for="(logo, index) in clientLogos",
                :key="`${logo}-${index}-1`",
                :src="require(`@/assets/logos/`+logo)",
                :class="isUsingSafari() ? '' : 'logo-pad'")
              img.py-2(
                v-for="(logo, index) in clientLogos",
                :key="`${logo}-${index}-2`",
                :src="require(`@/assets/logos/`+logo)",
                :class="isUsingSafari() ? '' : 'logo-pad'")
      .col-6.d-none.d-lg-flex
        img.d-block.w-100.my-auto(:src="require(`@/assets/images/homepage-hero.svg`)")

    .page-break
    Testimonial
    .page-break
    .row
      .offset-lg-1.col-lg-10.px-0
        featured-blog(
          v-for="(blog, index) in featuredBlogs",
          :key="`landing-page-featured-blog-${blog.id}`",
          :index="index",
          :length="featuredBlogs.length",
          :blog="blog",
          :imagePos="index%2==0?'right':'left'")

    .page-break
    .h1.text-md-center.mb-5.text-sm-left
      .hb1.text-gray-900 Considering insurance options for your company?
      .text-gray-800 We’ll make sure you make the right call

    .row
      .col-lg-10.offset-lg-1
        GetInTouch
    .page-break
    .row.no-gutters
      .offset-lg-1
        h2.hb2 Want to learn more about Employee Health Insurance and Benefits?

    .row.d-none.d-lg-flex
      .col-lg-10.offset-lg-1
        .row
          latest-blog.px-3.py-4(
            v-for="blog in latestBlogs",
            :key="`landing-page-latset-blog-web-${blog.id}`",
            :blog="blog")

  .row.no-gutters.mx-lg-4.d-flex.d-lg-none
    .col-12.col-lg-10.offset-lg-1
      br
      .row.no-gutters.flex-nowrap.overflow-x-auto
        b-card.col-12.col-sm-9.latest-blog-card.py-2.mx-3.my-2.h-auto(
          v-for="blog in latestBlogs",
          :key="`landing-page-latest-blog-mobile-${blog.id}`",
          style="width: 400%;")
          latest-blog.px-3(:blog="blog")
        br

  .container.container-homepage.mt-4
    .pb-4.text-center
      b-link(href="https://blog.getnovaapp.com/", target="_blank")
        n-button.rounded-sides.mt-4.px-4(variant="outline-secondary2", buttonText="View more articles ->")
    .page-break
    .row
      .col-lg-10.offset-lg-1
        FAQ
    .page-break

  n-info-modal(:id="'info-modal-1'")
  get-in-touch-modal(:id="'git-modal-from-phone-number-field'" :heroPhone="heroPhone")

</template>

<script>
import NovaNavbar from "@/layout/NovaNavbar.vue";
import NButton from "@/components/NovaButton.vue";
import NInlineInput from "@/components/NovaInlineInput.vue";
import NInfoModal from "@/components/NovaInfoModal.vue";
import utils from "@/utils";
import gql from "graphql-tag";
import resDefs from "../forms/definitions.js";
import FeaturedBlog from "./components/FeaturedBlog.vue";
import LatestBlog from "./components/LatestBlog.vue";
import Testimonial from "./components/Testimonial.vue";
import GetInTouch from "./components/GetInTouch.vue";
import FAQ from "./components/FAQ.vue";
import GetInTouchModal from "./components/GetInTouchModal.vue";

export default {
  name: "Homepage",
  components: {
    NovaNavbar,
    NButton,
    NInlineInput,
    NInfoModal,
    FeaturedBlog,
    LatestBlog,
    Testimonial,
    GetInTouch,
    GetInTouchModal,
    FAQ,
  },
  data() {
    return {
      latestBlogs: [],
      featuredBlogs: [],
      heroPhone: "",
      clientLogos: [
        "snapdeal.svg",
        "dream11.svg",
        "coindcx.svg",
        "chumbak.svg",
        "yulu.svg",
        "fisdom.svg",
        "jumbotail.svg",
      ],
    };
  },
  async created() {
    this.$Progress.start();
    this.$apollo.addSmartQuery("featuredBlogs", {
      query: gql`
        query ghostBlogsQuery($blogset: String) {
          ghostBlogs(blogset: $blogset)
        }
      `,
      variables: () => ({
        blogset: "featured",
      }),
      update(data) {
        return data.ghostBlogs;
      },
    });

    this.$apollo.addSmartQuery("latestBlogs", {
      query: gql`
        query ghostBlogsQuery($blogset: String) {
          ghostBlogs(blogset: $blogset)
        }
      `,
      variables: () => ({
        blogset: "latest",
      }),
      update(data) {
        return data.ghostBlogs;
      },
    });
    this.$Progress.finish();
  },
  methods: {
    showModal() {
      this.$bvModal.show("info-modal-1");
    },
    openCalendlyPopup() {
      utils.openCalendlyPopup();
    },
    async submitHeroPhoneNumber() {
      if (!utils.validateIndianMobileNumbers(this.heroPhone)) {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Please enter a valid mobile number!",
        });
        return;
      }
      try {
        const result = await this.$apollo.mutate({
          mutation: resDefs.starterForm.createFormResponse,
          variables: {
            meta: {
              data: {
                contact: this.heroPhone,
              },
              landing: "HomePageHeroCTA",
            },
            formSlug: "website-leads",
          },
        });
        if (result) {
          this.$store.commit("addAlert", {
            variant: "success",
            message: "Thanks, Our team will get back to you soon!",
          });
          this.$bvModal.show("git-modal-from-phone-number-field");
        }
      } catch (err) {
        console.log(err);
      }
    },
    isUsingSafari: utils.isUsingSafari,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.input-tiber {
  border-color: $tiber;
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

.nav-link {
  color: $gray-700;
  margin-right: 5rem;
}

.hero-arrow {
  z-index: -1;
  position: absolute;
  left: -10rem;
  top: -5%;
}
.hero-arrow-mobile {
  z-index: -1;
  position: absolute;
  transform: rotate(-65deg);
  left: -2.5rem;
  top: 50%;
}
.hero-divider {
  hr {
    width: 44%;
  }
  span {
    width: 10%;
  }
}

.tiber-bg {
  background: $tiber;
  max-width: 100vw !important;
}

.modal-open {
  overflow-y: scroll;
  padding: 0 !important;
}

.page-title {
  color: #87eae3;
}
.container-table {
  width: 100%;
  padding: 0;
  z-index: 0;
}
.input-group-append .btn {
  z-index: 1;
}
.latest-blog-card > .card-body {
  padding: 0;
}
.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}
.input-group {
  max-width: 350px;
}

.logos-container {
  overflow: hidden;
  height: 40px;
  transform: translate3d(0, 0, 0);
}

.logos {
  height: 100%;
  transform: translate3d(0, 0, 0);
  animation: moveSlideshow 20s linear infinite;

  img {
    height: 100%;
  }

  .logo-pad {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-962px);
  }
}
</style>
