<template lang="pug">
b-button(v-bind="{...$props, ...$attrs}", v-on="$listeners", :class="isDarkBG ? 'white-bg' : '' ")
  .d-flex.align-items-center.justify-content-center
    n-icon.align-sub(v-if="imageIcon", :name="imageIcon", :size="size === 'sm' ? 1 : 1.3", :class="[buttonText ? `mr-2` : '']")
    div(:class="{ 'text-decoration-underline': textUnderline }")
      span.font-weight-medium {{ buttonText }}
    n-icon.align-sub(v-if="rightImageIcon", :name="rightImageIcon", :size="size === 'sm' ? 1 : 1.3", :class="[buttonText ? `ml-2` : '']")
  
</template>

<script>
import NIcon from "./NovaIcon.vue";
export default {
  name: "NButton",
  components: {
    NIcon
  },
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
    imageIcon: {
      type: String,
      default: null,
    },
    rightImageIcon: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    isDarkBG: {
      type: Boolean,
      default: false,
    },
    textUnderline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.white-bg {
  &:hover,
  &:active,
  &.active,
  &:focus,
  &.focus,
  &.active:focus,
  &.active.focus,
  &:active.focus,
  &:active:focus {
    background: $white !important;
    border-color: $white !important;
    & > * {
      color: $gray-900 !important;
    }
  }
}
</style>
