<template lang="pug">
b-badge.n-chip.d-inline-flex.align-items-center(
  v-bind="{ ...$props, ...$attrs }",
  :class="isMinimal ? 'minimal-chip' : ''"
)
  span(v-if="chipIcon")
    i.mr-2.h5.m-0.align-text-bottom(:class="`icon-${chipIcon} ${iconWhite ? 'chip-icon' : ''}`")
  template(v-else)
    slot(name="icon")
  template.font-weight-medium.font-xs
    slot(name="default")

  template(v-if="$slots.secondary")
    span.mx-2.text-grey-900 |
    slot(name="secondary")
</template>

<script>
export default {
  name: "NChip",
  props: {
    chipIcon: {
      type: String,
      default: null,
    },
    isMinimal: {
      type: Boolean,
      default: false,
    },
    iconWhite: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.n-chip {
  padding: 0.25rem 0.625rem;
  border-radius: 0.375rem;
  max-width: max-content;
  cursor: pointer;

  .chip-icon {
    color: $white;
  }
}
.badge-pill {
  border-radius: 10rem;
}

.minimal-chip {
  color: $blackpearl;
  border: 1px solid $gray-300;
  background-color: $white !important;
}
</style>
