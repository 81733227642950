import gql from "graphql-tag";
import Handlebars from "handlebars";
import { kebabCase, pickBy } from "lodash-es";

const inrFormatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const newImageFromBlob = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};

const getCanvasBlob = (canvas, mime, quality) => {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(
      function (blob) {
        resolve(blob);
      },
      mime,
      quality
    );
  });
};

const calculateSize = (img, maxWidth, maxHeight) => {
  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
};

export default {
  getClientIntroScreenVersion() {
    const clientCookieValue = ("; " + document.cookie).split(`; INTRO_SCREEN_VERSION=`).pop().split(";")[0];
    return clientCookieValue === "" ? 0 : clientCookieValue;
  },
  handleApolloErrors(err, store) {
    let msgs = [];
    console.log("apollo err", err);
    window.apolloErr = err;
    if (err.networkError) {
      msgs.push("Network Error: " + err.networkError.toString());
    }
    if (err.graphQLErrors) {
      err.graphQLErrors.forEach((x) => {
        msgs.push(x.message);
        if (x.state) Object.values(x.state).forEach((m) => (msgs = msgs.concat(m)));
      });
    }
    if (msgs.length === 0) msgs.push("Unknown error in GQL: " + err.toString());
    if (store) msgs.forEach((message) => store.commit("addAlert", { variant: "danger", message }));
    return msgs;
  },
  quickHash(str) {
    var hash = 0;
    var i = 0;
    var len = str.length;
    while (i < len) {
      hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
    }
    return hash + 2147483647 + 1;
  },
  statusToIconDetails(status) {
    const map = {
      draft: "progress-1",
      "org-ok": "progress-2",
      "nova-ok": "progress-3",
      "provider-ok": "progress-4",
      done: "progress-4",
      archive: "progress-5",
    };
    return map[status];
  },
  createObjectWithNkeysOfSameValue(keys, value) {
    const newObject = {};
    keys.forEach((key) => (newObject[key] = value));
    return newObject;
  },
  createEmptyBatchSummary() {
    const initialStatsSummary = { count: 0, dependents: 0, users: 0 };
    const types = ["changes", "add", "delete", "update", "approved", "rejected"];
    return this.createObjectWithNkeysOfSameValue(types, initialStatsSummary);
  },
  updateBatchSummaryFromUserChange(userChange, batchSummary) {
    const type = userChange.type;
    batchSummary[type].count += 1;
    if (userChange.dependentId) {
      batchSummary[type].dependents += 1;
    } else {
      batchSummary[type].users += 1;
    }
    return batchSummary;
  },
  toCamelCase(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (ch, idx) => (idx === 0 ? ch.toLowerCase() : ch.toUpperCase()))
      .replace(/\s+/g, "");
  },
  toPascalCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (ch, idx) => ch.toUpperCase()).replace(/\s+/g, "");
  },
  deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  toINR(x, withRupeeSym = true) {
    if (x < 0) return (withRupeeSym ? "- ₹ " : "") + inrFormatter.format(x).slice(2);
    return (withRupeeSym ? "₹ " : "") + inrFormatter.format(x).slice(1);
  },
  toGlobalId(type, id) {
    return btoa([type, id].join(":"));
  },
  toPossesiveForm(str) {
    if (!str || str === "") {
      return null;
    }
    return `${str}'s`;
  },
  fromGlobalId(gid) {
    const [type, id] = atob(gid).split(":", 2);
    return { type, id };
  },
  async toNanoId(vueInstance, globalId) {
    const result = await vueInstance.$apollo.mutate({
      mutation: gql`
        mutation toNanoId($globalId: String!) {
          toNanoId(input: { globalId: $globalId }) {
            nanoId
          }
        }
      `,
      variables: {
        globalId: globalId,
      },
    });
    return result.data.toNanoId.nanoId;
  },
  async fromNanoId(vueInstance, nanoId) {
    const result = await vueInstance.$apollo.mutate({
      mutation: gql`
        mutation fromNanoId($nanoId: String!) {
          fromNanoId(input: { nanoId: $nanoId }) {
            globalId
          }
        }
      `,
      variables: {
        nanoId: nanoId,
      },
    });
    return result.data.fromNanoId.globalId;
  },
  openCalendlyPopup() {
    /* eslint-disable-next-line  no-undef */
    Calendly.showPopupWidget(`https://calendly.com/${process.env.VUE_APP_CALENDLY_SCHEDULING_LINK}`);
  },
  filterPlan(items, selectedPlan) {
    const allItems = [];
    items.forEach((item) => {
      item[item.feature] = item[selectedPlan];
      const result = pickBy(item, (value, key) => key === item.feature);
      allItems.push(result);
    });
    return allItems;
  },
  getNameInitials(name) {
    return (
      name
        .match(/(\b\S)?/g)
        ?.join("")
        .match(/(^\S|\S$)?/g)
        ?.join("")
        .toUpperCase() || ".."
    );
  },
  getFormattedNumber(number) {
    if (isNaN(number)) return null;
    const nfObject = new Intl.NumberFormat("hi-IN");
    return nfObject.format(number);
  },
  getFormattedINRValue(value) {
    return isNaN(value)
      ? value
      : this.toINR(value, false);
  },
  saveVisitorInfoZoho(user) {
    window.$zoho.salesiq.visitor.name(user?.name);
    window.$zoho.salesiq.visitor.info({
      org: user?.org?.name,
    });
    window.$zoho.salesiq.visitor.email(user?.email);
  },
  checkElemExistsById(id) {
    return new Promise((resolve, reject) => {
      let counter = 0;
      const checkExist = setInterval(function () {
        counter++;
        if (document.getElementById(id)) {
          clearInterval(checkExist);
          resolve(true);
        }
        if (counter > 10) {
          resolve(false);
          clearInterval(checkExist);
        }
      }, 250);
    });
  },
  async hideZohoSI() {
    if (await this.checkElemExistsById("contact-support-container")) {
      document.getElementById("contact-support-container").style.display = "none";
    }
  },
  async showZohoSI() {
    if (await this.checkElemExistsById("contact-support-container")) {
      document.getElementById("contact-support-container").style.display = "block";
    }
  },
  getOptionsMapperForStrapi(queryName, strapiType = null) {
    return (data) => {
      const options = [];
      data[queryName]?.edges.forEach((item) => {
        if (!strapiType || strapiType === item.node.meta.type) {
          const { id, slug, meta } = item.node;
          options.push({
            id,
            slug,
            meta,
            name: `${slug} (${meta.id})`,
          });
        }
      });
      return options;
    };
  },
  getOptionsMapperForSibTemplates(queryName) {
    return (data) =>
      data[queryName].map((template) => ({
        id: template.id,
        name: `${template.name} (${template.id})`,
      }));
  },
  getLinkMapperForStrapi(collectionType) {
    // convert pascalCase to kebab-case for building url
    const collectionSlug = kebabCase(collectionType);
    return (item) =>
      `${
        process.env.VUE_APP_STRAPI_CMS_URL
      }/admin/plugins/content-manager/collectionType/application::${collectionSlug}.${collectionSlug}/${
        item?.meta?.id || item?.id
      }`;
  },
  async getCompressedImage(file, MAX_HEIGHT, MAX_WIDTH, MIME_TYPE, QUALITY) {
    const blobURL = URL.createObjectURL(file);
    const img = await newImageFromBlob(blobURL);
    URL.revokeObjectURL(this.src);
    const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
    const canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    const compressedBlob = await getCanvasBlob(canvas, MIME_TYPE, QUALITY);
    const compressedImage = new File([compressedBlob], `${file.filename}.jpg`, {
      type: "image/jpeg",
    });
    return compressedImage;
  },
  flushPaginationCachedData(store, resName) {
    const rootQuery = { ...store.data.data.ROOT_QUERY };
    Object.keys(rootQuery).forEach((key) => {
      if (key.startsWith(resName)) {
        delete rootQuery[key];
      }
    });
    // mutating the store with new rootQuery
    store.data.data.ROOT_QUERY = { ...rootQuery };
  },

  getSingularOrPlural(word, count) {
    return count + " " + (count === 1 ? word : word + "s");
  },
};

export function getFitnessValueMultiplierByUnit(rates, unit) {
  return 1 / rates[unit] || 1;
}

export function roundToPrecision(value, digitsAfterDecimal) {
  return Math.round(value * Math.pow(10, digitsAfterDecimal)) / Math.pow(10, digitsAfterDecimal);
}

export function onWheelNumInputBlur(event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
}

export function useHandlebars(text, valuesObject) {
  const template = Handlebars.compile(text);
  return template(valuesObject);
}

export function showSalesIqChat() {
  if (window.$zoho) {
    window.$zoho.salesiq.floatwindow.visible("show");
  }
}

export function mapBenefitTypeToIcon(benefitType) {
  const map = {
    gmc: { icon: "hospital", variant: "danger" },
    gpa: { icon: "wheelchair", variant: "warning" },
    gtl: { icon: "bed", variant: "success" },
    covid: { icon: "covid", variant: "primary" },
    dental: { icon: "teeth", variant: "primary" },
    telemedicine: { icon: "mobile", variant: "light-secondary" },
    ppc: { icon: "hospital", variant: "danger" },
  };
  return map[benefitType] ? map[benefitType] : { icon: "hospital", variant: "danger" };
}

export async function nukeStrapiCache(cacheKeyToBeNuked, vueInstance, mutation) {
  const variables = {
    file: null,
    cmdName: "nukeCache",
    args: vueInstance.cmdDef.transformArgs({
      cacheKeyToBeNuked,
    }),
  };
  await vueInstance.$apollo.mutate({
    context: {
      hasUpload: true, // Important!
    },
    mutation,
    variables,
  });
}

export function oldClaimContentStrapiMapper(type) {
  const mapping = {
    cashlessContent: "Cashless",
    emergencyContent: "Emergency",
    reimbursementContent: "Reimbursement",
  };
  return mapping[type];
}

export function getContentFromPolicyClaimContent(content, type) {
  return content?.dynamicContent[0]?.["PolicyClaimsProcessContent"].find((item) => {
    return item.claimType === type;
  });
}

export function getContentFromOldClaimContent(content, type) {
  return content?.[type]?.dynamicContent?.[0].claimFormsLink;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
